'use client'

export default function GlobalError(
    {
        reset,
    }: {
        error: Error & { digest?: string }
        reset: () => void
    }
) {
    return (
        <html>
        <body>
        <h2>Something went really wrong!</h2>
        <button onClick={() => reset()}>Try again</button>
        </body>
        </html>
    )
}
